import React from 'react';
import Main from './Main';

const App = () => {

  return (
    <div>
        <Main />
    </div>
  )
}

export default App