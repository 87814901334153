import React from 'react';
import Neja from '../Assets/neja.png';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { ReactComponent as Book } from '../Assets/book.svg';

const About = ({ }) => {

  return (
    <div className='about-me'>
      <Container>
      <Grid container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={6}>
          <h1>About me</h1>
          <p>Computing Science student at the University of Dundee, who spends more of her time abroad than in Scotland. Originally from Slovenia, I have spent the past years in the UK, China and Canada.</p>
          <p>In an ideal world I would be spending all my time at work running Design Sprints and other workshops, talking to customers, gathering insight and rapidly prototyping interactive solutions.</p>
          <div className='centered'>⋅</div>
          <p>Aside from designing experiences and developing new products you can find me travelling and taking photos around the world, reading plenty of books in bed, trying out new vegetarian recipes and training dogs.</p>
          {/* <div className='recommendation'><Book /> <b>Visited</b> <a target="_blank" rel="noopener noreferrer" href="https://www.goodreads.com/book/show/41104077-invisible-women">30 countries!</a></div> */}
          {/* <div className='recommendation'><Book /> <b>Recommendation</b> <a target="_blank" rel="noopener noreferrer" href="https://www.goodreads.com/book/show/41104077-invisible-women">Invisible Women</a></div> */}
          {/* <div className='recommendation'><Book /> <b>Recipe</b> <a target="_blank" rel="noopener noreferrer" href="https://www.goodreads.com/book/show/41104077-invisible-women">Baked gnocchi</a></div> */}
        </Grid>
        <Grid item xs={12} sm={6}>
          <img src={Neja} width="500px" alt="Neja's profile picture" />
        </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default About