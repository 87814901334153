import React from 'react';
import { ReactComponent as Mail } from '../Assets/mail.svg';
import { ReactComponent as Linkedin } from '../Assets/logo-linkedin.svg';
import { ReactComponent as Cv } from '../Assets/cv.svg';
import Pdf from '../Assets/Neja-Cesnik-CV.pdf';
import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';
import { Link } from "react-router-dom";

const Hons = ({ }) => {

    return (
      <div>
      <Container>
      <div className='icons'>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="flex-start"
      >
      <div className='icon'>
        <a target="_blank" rel="noopener noreferrer" href="mailto:neja.cesnik@gmail.com"><Mail /></a>
      </div>
      <div className='icon'>
        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/neja-cesnik/"><Linkedin /></a>
      </div>
      <div className='icon'>
        <a target="_blank" rel="noopener noreferrer" href={Pdf}><Cv /></a>
      </div>
      </Grid>
      </div>
      <h1>Web Application to Support the Design Sprint Process</h1>
      <div className='desc'>Design Sprint is a methodology developed by Google Ventures to help innovate and explore new ideas, prototype and test them. This is achieved with a series of tasks and activities split between five days. Ever since the pandemic, the Sprint has been moved online, creating a range of new obstacles which require changing of the original Design Sprint format. New facilitators feel reluctant to use it in the current situation, as there are not many in-depth resources to help them plan one. Sprint planning has become one of the most important tasks and the only way to assure great results.</div>
      <hr />
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
      >
      <Grid item sm={4}>
        <h3>Roles and Responsabilities</h3>
        <p>
          <li><b>User Research</b> Literature Review, Requirements Gathering, User Interviews, Problem Framing, Ideating</li>
          <li><b>UX Design</b> Sketching, Wireframing, Iterative Prototyping</li>
          <li><b>Development</b> MERN Web Application</li>
        </p>
      </Grid>
      <Grid item sm={2}>
        <h3>Projects Context</h3>
        <p>
          <li>Autumn 2020 - Ongoing</li>
          <li>Final Year Project</li>
          <li>Solo Project</li>
        </p>
      </Grid>
      <Grid item sm={2}>
        <h3>Deliverables</h3>
        <p>
          <li>Interactive Prototype</li>
          <li>Web Application</li>
          <li>Honours Report</li>
        </p>
      </Grid>
      <Grid item sm={2}>
        <h3>Tools Used</h3>
        <p>
          <li>Miro</li>
          <li>Adobe XD</li>
          <li>React</li>
        </p>
      </Grid>
      </Grid>
      </Container>
      </div>
    )
  }
  
  export default Hons