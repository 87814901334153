import React from 'react';
import { ReactComponent as Mail } from '../Assets/mail.svg';
import { ReactComponent as Linkedin } from '../Assets/logo-linkedin.svg';
import { ReactComponent as Cv } from '../Assets/cv.svg';
import Pdf from '../Assets/Neja-Cesnik-CV.pdf';
import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';
import { Link } from "react-router-dom";

const UX = ({ }) => {

    return (
      <div>
      <Container>
      <div className='icons'>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="flex-start"
      >
      <div className='icon'>
        <a target="_blank" rel="noopener noreferrer" href="mailto:neja.cesnik@gmail.com"><Mail /></a>
      </div>
      <div className='icon'>
        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/neja-cesnik/"><Linkedin /></a>
      </div>
      <div className='icon'>
        <a target="_blank" rel="noopener noreferrer" href={Pdf}><Cv /></a>
      </div>
      </Grid>
      </div>
      <h1>Virtual University Open Day Concept</h1>
      <div className='desc'>Website which allows users to experience a remote Open Day at the Univeristy of Dundee. Participants can virtually travel around the campus and visit building on a interactive map, watch the presentations and live sessions, browse the on-demand material and form meaningful connections with other applicants, current students or staff through messages and group chats.</div>
      <hr />
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
      >
      <Grid item sm={3}>
        <h3>Roles and Responsabilities</h3>
        <p>
          <li><b>User Research</b> Requirements Gathering, Design Sprint, Problem Framing, Journey Mapping, Ideating</li>
          <li><b>UX Design</b> Sketching, Iterative Prototyping</li>
        </p>
      </Grid>
      <Grid item sm={3}>
        <h3>Projects Context</h3>
        <p>
          <li>Autumn 2020</li>
          <li>UX Course Assignment</li>
          <li>Team Project</li>
        </p>
      </Grid>
      <Grid item sm={3}>
        <h3>Deliverables</h3>
        <p>
          <li>Paper Prototype</li>
          <li>Interactive Prototype</li>
          <li>Atomic Design System</li>
          <li>Design Sprint Process Report</li>
        </p>
      </Grid>
      <Grid item sm={3}>
        <h3>Tools Used</h3>
        <p>
          <li>Miro</li>
          <li>Marvel</li>
          <li>Figma</li>
          <li>Adobe XD</li>
        </p>
      </Grid>
      </Grid>
      </Container>
      </div>
    )
  }
  
  export default UX